import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')
];

export const server_loads = [3,2];

export const dictionary = {
		"/(app)": [~12,[3],[4]],
		"/(app)/404": [13,[3],[4]],
		"/(app)/500": [14,[3],[4]],
		"/(app)/about": [15,[3],[4]],
		"/(app)/about/privacy-policy": [16,[3],[4]],
		"/(app)/about/terms-of-service": [17,[3],[4]],
		"/(app)/add-review": [~18,[3],[4]],
		"/(admin)/admin": [~5,[2]],
		"/(admin)/admin/buildings/images": [6,[2]],
		"/(admin)/admin/buildings/new": [7,[2]],
		"/(admin)/admin/buildings/requests": [8,[2]],
		"/(admin)/admin/reviews": [9,[2]],
		"/(admin)/admin/reviews/[review_uuid]": [~10,[2]],
		"/(admin)/admin/users": [11,[2]],
		"/(app)/b-[building_uuid]": [~19,[3],[4]],
		"/(app)/buildings/add-new": [~23,[3],[4]],
		"/(app)/buildings/search": [~24,[3],[4]],
		"/(app)/buildings/[locality]": [~20,[3],[4]],
		"/(app)/buildings/[locality]/[sub_locality]-n[id=integer]": [~21,[3],[4]],
		"/(app)/buildings/[locality]/[sub_locality]-n[id=integer]/[slug]": [~22,[3],[4]],
		"/(app)/e/email/unsubscribe": [~25,[3],[4]],
		"/(app)/e/email/unsubscribe/complete": [~26,[3],[4]],
		"/(app)/login": [~27,[3],[4]],
		"/(app)/me": [~28,[3],[4]],
		"/(app)/s/[building_slug]": [~29,[3],[4]],
		"/(app)/welcome": [~30,[3],[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';