import * as Sentry from '@sentry/sveltekit';
import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';
import { PUBLIC_ENV } from '$env/static/public';

Sentry.init({
    dsn: 'https://613b90fff1e845a0928d5212bbcc60e2@o4505228080709632.ingest.sentry.io/4505529721356288',
    tracesSampleRate: PUBLIC_ENV === 'production' ? 1.0 : 0.01,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: PUBLIC_ENV === 'production' ? 1.0 : 0.1,

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [new Replay()],
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
